import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    private loadingObject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isLoading = this.loadingObject.asObservable();
    public constructor(private readonly ngxSpinnerService: NgxSpinnerService) { }

    public show() {
        this.ngxSpinnerService.show();
    }

    public hide() {
        this.ngxSpinnerService.hide();
    }
}
